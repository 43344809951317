import { Component, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MediaMatcher } from '@angular/cdk/layout';
import { map, filter, switchMap } from 'rxjs/operators';
import { Base64 } from "js-base64";
import { Observable, of, observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Console } from '@angular/core/src/console';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  mobileQuery: MediaQueryList;
  hide = true;

  public pwd: String = "";
  public bTouch = false;
  public strValid: string = "loading";
  public strError: string = "";

  public title$: Observable<String> = of("Loading...");
  public img$: Observable<String> = null;
  public configBody:any = null;

  public static b64DecodeUnicode(str: string): string {
    if (window && "atob" in window && "decodeURIComponent" in window) {
      return decodeURIComponent(
        Array.prototype.map
          .call(atob(str), c => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
    } else {
      console.warn(
        "b64DecodeUnicode requirements: window.atob and window.decodeURIComponent functions"
      );
      return null;
    }
  }

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private route: ActivatedRoute,
    private http:HttpClient
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    const param$ = this.route.queryParams.pipe(
      filter(x => (x.param ? true : false)),
      map(param => {
        // console.log(param.param);
        const data: string = param.param;
        const out = decodeURI(data);
        //console.log("uri " +out);
        const decoded = AppComponent.b64DecodeUnicode(out);
        // let decoded = AppComponent.b64DecodeUnicode(out);
        // console.log(decoded);

        // console.log(JSON.parse(decoded));
        return decoded;
      }),
      map(x => JSON.parse(x))
    );
    //.subscribe(x => console.log("final " + x));

    this.title$ = param$.pipe(map(x => x.title));
    this.img$ = param$.pipe(map(x => x.img));


    let starterSub = param$.pipe(
      switchMap( data =>{


        this.configBody = data;
        let { email, url_check_valid, hash_pwd, success_message , img , title } = data ;

        const body = {
          email,hash_pwd
        }
        
        return this.http.post<any>( url_check_valid, body );
      })
    ).subscribe(
      x=>{
        console.log(x)

        let data = x["result"];

        if (data != null ) {
          const valid = data['valid'];

          console.log(valid);
          if ( valid ) {

            this.strValid = "ready";
            starterSub.unsubscribe();
            return;

          } else {
            this.strValid = "error";
            this.strError = data['message'];
            starterSub.unsubscribe();
            return;

          }
        }

        this.strValid = "ready";
        starterSub.unsubscribe();
      },
      err=>{
        console.dir(err);
        this.strError = err.message;
        // this.title$ = of<String>("Error");
        this.strValid = "error";
        starterSub.unsubscribe();
      }
    )
//  --data - binary '{"query":"# Write your query or mutation here\nmutation {\n  resetPassword(\n    data:{\n      email:\"aAa@maya-wizard.com\"\n      hash_pwd:\"asdf\"\n      password:\"maetee6283\"\n    }\n  ) \n}"}' --compressed

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onChange():void {
    // console.log(this.pwd);
    this.bTouch = true;
  }

  onSave():void {

    if (this.configBody == null ) return;

    this.strValid = "changing";

    let { email, url_change_pwd, hash_pwd, success_message, img, title } = this.configBody;

    // const body = {
    //   query: `
    //                 mutation {
    //                   resetPassword(
    //                     data:{
    //                       email:"${email}"
    //                       hash_pwd:"${hash_pwd}"
    //                       password:"${this.pwd}"
    //                     }
    //                   ) 
    //                 }`
    // };
    // console.log(body)
    const body = {
      email,
      hash_pwd,
      password : this.pwd
    }

    const starterSub = this.http.post(url_change_pwd, body).subscribe(
      x => {
        console.log(x)

        let data = x["result"];

        if ( x["statusCode"] == '200' && data && data.message ) 
        {
          const valid = data['valid'];

          console.log(valid);
          if ( ( valid != undefined ) && (valid==false) ) {
            this.strValid = "error";
            this.strError = data['message'];
          } else {
            this.strValid = 'success';
          }

        } else {
          
          this.strValid = "error";

          if ( x["message"] )
            this.strError = x["message"];
        }

        starterSub.unsubscribe();
      },
      err => {
        // console.dir(err);
        this.strError = err.message;

        // this.title$ = of<String>("Error-001");
        this.strValid = "error";
        starterSub.unsubscribe();
      }
    );

  }
}
