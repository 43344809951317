import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({providedIn: 'root'})
export class AesencService {

  private key = CryptoJS.enc.Utf8.parse('kXp2s5v8ysBvEuHzMbQeThVmYq3t6w9z');
  private iv = CryptoJS.enc.Utf8.parse('3s6v9yhBjEvHuMcQ');

  constructor() { }

  getEncrypt( data: any , key : any = null ,  iv : any = null ): string {

    let attatch_string: string = '';
    if (typeof data === 'string' || data instanceof String) {
      attatch_string = data.toString();
    } else {
      attatch_string = JSON.stringify(data);
      // console.log("convert json to string:" + attatch_string);
    }

    let useKey = this.key;
    let useiv = this.iv;

    if ( key != null ) useKey = key;
    if ( iv != null ) useiv = iv;

    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(attatch_string), useKey,
        {
            keySize: 128 / 8,
            iv: useiv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

    return encrypted.toString();
  }

  getDecrypt( encryptedData : string , key : any = null ,  iv : any = null ) : any {

    let useKey = this.key;
    let useiv = this.iv;

    if ( key != null ) useKey = key;
    if ( iv != null ) useiv = iv;    

    var decrypted = CryptoJS.AES.decrypt(encryptedData, useKey, {
        keySize: 128 / 8,
        iv: useiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
  
    if ( decrypted === null ) return;
    // console.log(decrypted.toString(CryptoJS.enc.Utf8));
    let json_obj = JSON.parse( decrypted.toString(CryptoJS.enc.Utf8) );
    if ( json_obj === null ) return decrypted ;

    return json_obj;

  }

  // getTest() {
  //   var key = CryptoJS.enc.Utf8.parse('kXp2s5v8ysBvEuHzMbQeThVmYq3t6w9z');
  //   var iv = CryptoJS.enc.Utf8.parse('3s6v9yhBjEvHuMcQ');
  //   var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse("It works"), key,
  //       {
  //           keySize: 128 / 8,
  //           iv: iv,
  //           mode: CryptoJS.mode.CBC,
  //           padding: CryptoJS.pad.Pkcs7
  //       });

  //   var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
  //       keySize: 128 / 8,
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7
  //   });

  //   console.log('Encrypted :' + encrypted);
  //   console.log('Key :' + encrypted.key);
  //   console.log('Salt :' + encrypted.salt);
  //   console.log('iv :' + encrypted.iv);
  //   console.log('Decrypted : ' + decrypted);
  //   console.log('utf8 = ' + decrypted.toString(CryptoJS.enc.Utf8));
  // }

}
