// import { Observable } from 'rxjs/Observable';
// import {HttpClient} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator , MatSort} from '@angular/material';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap, delay} from 'rxjs/operators';
import { ServerconnectService } from '../../../services/serverconnect.service';

@Component({
  selector: 'cms-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css']
})
export class ListingComponent implements OnInit {

  displayedColumns = [ 'id' , 'inserted_date','username' , 'action' , 'application' , 'ip_user'
//  , 'username', 'application'
];
  data: any[] = [];

  resultsLength = 0;
  public isLoadingResults = true;
  public isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private req : ServerconnectService
  ) {}

  ngOnInit() {
    // this.exampleDatabase = new ExampleHttpDao(this.http);

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.paginator.pageSize = 10;
    
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          const param = {
            "token":"1476136270_57fc0d4e95137",
            "cmd":{
              "table_des":"SHOW COLUMNS FROM sec_sc_log",
              "all":"select count(*) as num from sec_sc_log",
              "list":"select * from sec_sc_log limit "+this.paginator.pageIndex*this.paginator.pageSize+" , " + this.paginator.pageSize
            }
          };
      
          return this.req.request('rapi_001' , param );          
          // return this.exampleDatabase!.getRepoIssues(
          //   this.sort.active, this.sort.direction, this.paginator.pageIndex);
        }),
        delay(1000),
        map(result => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = result['data']['all'][0]['num'];

          return result['data']['list'];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.data = data;
      });
  }

}



// export interface GithubApi {
//   items: any[];
//   total_count: number;
// }

// export interface GithubIssue {
//   created_at: string;
//   number: string;
//   state: string;
//   title: string;
// }

// /** An example database that the data source uses to retrieve data for the table. */
// export class ExampleHttpDao {
//   constructor(private http: HttpClient) {}

//   getRepoIssues(sort: string, order: string, page: number): Observable<GithubApi> {
//     const href = 'https://api.github.com/search/issues';
//     const requestUrl =
//         `${href}?q=repo:angular/material2&sort=${sort}&order=${order}&page=${page + 1}`;

//     return this.http.get<GithubApi>(requestUrl);
//   }
// }