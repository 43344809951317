import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable , of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AesencService } from './aesenc.service';
import { environment } from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class ServerconnectService {

  base_api_url: string = environment.apis.host_basic_v1.url;
  key: string = environment.apis.host_basic_v1.key;
  iv: string = environment.apis.host_basic_v1.iv;
  

  constructor(
    private http: HttpClient,
    private aes: AesencService
  ) { }

  // Support Encryption Decrypt
  request( api_name: string ,  data: any ): Observable<any> {
    return this.http.post<any>( this.base_api_url +api_name , this.aes.getEncrypt(data,this.key,this.iv) ).pipe(
      map( d => {
          if ( d && d['encryption'] !== undefined && d['encryption'] === 1 && ( typeof d.data === 'string') ) {
            return this.aes.getDecrypt(d.data ,this.key,this.iv );
          }
          return d;
      })
    );
  }
}
