import { Component, OnInit } from '@angular/core';
import { AesencService } from '../../services/aesenc.service';
import { ServerconnectService } from '../../services/serverconnect.service';

@Component({
  selector: 'cms-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {

  constructor( 
    // private enc : AesencService ,
    private req : ServerconnectService
  ){}

  ngOnInit() {
    // const testing = {hello:"test data"};
    // console.log(testing);
    // const strEnc = this.enc.getEncrypt(testing);
    // console.log(strEnc);
    // const decryptData = this.enc.getDecrypt(strEnc);
    // console.log(decryptData);

    const param = {
      "token":"1476136270_57fc0d4e95137",
      "cmd":{
        "table_des":"SHOW COLUMNS FROM sec_sc_log",
        "all":"select count(*) as num from sec_sc_log",
        "list":"select * from sec_sc_log limit 0 , 20"
      }
    };


    this.req.request('rapi_001' , param ).subscribe( d => console.log(d));

  }

}
